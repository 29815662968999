import { React, useState, useEffect } from "react";
import { Form, Input, DatePicker, TimePicker, Select, Button, Switch,Row,Col, Modal,Radio } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import RecurrenceModal from "./RecurrenceModal";

const { Option } = Select;

// Enum for delete options
const DELETE_OPTIONS = {
    DELETE_THIS: "deleteThis",
    DELETE_ALL: "deleteAll",
};
// Enum for edit options
const EDIT_OPTIONS = {
    EDIT_THIS: "editThis",
    EDIT_ALL: "editAll",
  };

const EventForm = ({ initialValues, onSave, onUpdate, onDelete ,sessions,teams, isEdit }) => {
    console.log("[Event Form] FIRST initialValues", initialValues);

    const [form] = Form.useForm(); // allows us to use methods like resetFields()
    const [eventType, setEventType] = useState(initialValues?.entity_type|| null); // rerender form when type of event changes. Allows user to attach session plan
    const [isRecurring, setIsRecurring] = useState(false);
    const [isRecurrenceModalVisible, setIsRecurrenceModalVisible] = useState(false);
    const [editRecurringEventModal,setEditRecurringEventModal] = useState(false);
    const [editOption,setEditOption] = useState(EDIT_OPTIONS.EDIT_THIS);
    const [formattedData, setFormattedData] = useState(null);
    const [deleteRecurringEventModal,setDeleteRecurringEventModal] = useState(false);
    const [deleteOption,setDeleteOption] = useState(DELETE_OPTIONS.DELETE_THIS);


    const getEntityId = (eventType, eventData) => {
        if (eventType === "Session" && eventData.session_id) {
            return eventData.session_id;
        }
        return null;
    };   
    const handleEditRecurringEvenConfirm = (eventData) =>{
        // here pass on data to event modal and if its all or just this
        onUpdate(formattedData, editOption);
        // Close the modal
        setEditRecurringEventModal(false);
    }
    const handleEditRecurringEvenCancel = () => {
        form.resetFields(); // Reset the form fields
        setEditRecurringEventModal(false);
    };
    const onFinish = (eventData) => { 
        const entity_id = getEntityId(eventType, eventData);
        // FORMAT DATA BEFORE SENDING
        const formattedData = {
            ...initialValues,
            ...eventData,
            event_id: initialValues?.event_id, 
            entity_id,
            entity_type: eventType, 
            date: eventData.date ? eventData.date.format("YYYY-MM-DD") : null,
            start_time: eventData.start_time ? eventData.start_time.format('HH:mm:ss') : null,
            end_time: eventData.end_time ? eventData.end_time.format('HH:mm:ss') : null,
        };
        if(eventData.recurrence){
            formattedData.recurrence = {
                frequency: eventData.recurrence.frequency,
                interval: eventData.recurrence.interval,
                startDate: eventData.date.format("YYYY-MM-DD"),
                endDate: eventData.recurrence.endDate 
            };
        }
        setFormattedData(formattedData); // use this state for editing recurring event function
        console.log("eventData", eventData);
        console.log("eventData.is_recurring", eventData.is_recurring);
        
        console.log("formattedData", formattedData);

        if (isEdit && onUpdate){ // updating an event
            if (formattedData.is_recurring) {
                // If the event is recurring, show the update modal
                setEditRecurringEventModal(true);
              } else {
                // If not recurring, proceed with the update
                onUpdate(formattedData); // pass values back to event modal then back to calendar page
              }
            

        }else{
            onSave(formattedData); // Pass form values back to calendarpage, creating new event
            form.resetFields();
        }
    };
    const validateEndTime = (getFieldValue) => (_, value) => { // make sure end time is after start time
        const start_time = getFieldValue("start_time");
        if (!value || !start_time || value.isAfter(start_time)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("End time must be after start time"));
    };
    const handleToggleChange = (checked) => {
        setIsRecurring(checked);
        if (checked) {
            setIsRecurrenceModalVisible(true);
        } else {
            form.setFieldsValue({ recurrence: null }); // Clear recurrence if turned off
        }
    }
    const handleRecurrenceModalSave = (recurrenceRule) => {
        form.setFieldsValue({ recurrence: recurrenceRule }); // Store recurrence in form
        setIsRecurrenceModalVisible(false); // Close the modal
    };
    const handleRecurrenceModalCancel = () => {
        setIsRecurring(false); // Reset the toggle
        setIsRecurrenceModalVisible(false); // Close the modal
    };
    // Handle delete button click
    const handleDeleteClick = () => {
       
        if (initialValues?.is_recurring) {
        // Open the modal if the event is recurring
        setDeleteRecurringEventModal(true);
        } else {
        // Delete immediately if not recurring
        onDelete({event_id: initialValues.event_id});
        }
    };
    // Confirm delete action
    const handleDeleteConfirm = () => {
        // Pass the delete option to the parent component
        console.log("HERE");
        onDelete({ event_id: initialValues.event_id, deleteOption });
        setDeleteRecurringEventModal(false);
    };

    // Cancel delete action
    const handleDeleteCancel = () => {
        setDeleteOption(DELETE_OPTIONS.DELETE_THIS);
        setDeleteRecurringEventModal(false);
    };
    
    return (
        <>
        <Form
            form={form}
            layout="vertical"
            name="eventForm"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={initialValues}
        >
            {/* Event Name Field */}
            <Form.Item
            name="event_name"
            label="Event Name"
            rules={[{ required: true, message: "Please enter the event name" }]}
            >
                <Input placeholder="Enter Event Name" />
            </Form.Item>
            {/* Event Type Field */}
            <Form.Item
            name="entity_type"
            label="Event Type"
            rules={[{ required: true, message: "Please select the event type" }]}
            >
            <Select
                placeholder="Select Event Type"
                onChange={(value) => setEventType(value)} // Use the selected value directly without converting to lowercase
            >
                <Option value="Session">Session</Option>
                <Option value="Match">Match</Option>
                <Option value="Meeting">Meeting</Option>
            </Select>
            </Form.Item>
            {/* Session Dropdown (only visible if event type is "Session") */}
            {eventType === "Session" && (
            <Form.Item name="session_id" label="Select Session">
                <Select placeholder="Select Session" allowClear>
                {sessions.map((session) => (
                    <Option key={session.session_id} value={session.session_id}>
                    {session.session_name}
                    </Option>
                ))}
                </Select>
            </Form.Item>
            )}
            <Form.Item name="team_id" label="Team" rules={[{ required: true, message: "Please select a team" }]}>
            <Select placeholder="Select Team">
                {teams.map((team) => (
                <Option key={team.team_id} value={team.team_id}>
                    {team.team_name}
                </Option>
                ))}
            </Select>
            </Form.Item>
            <Form.Item name="date" label="Date" rules={[{ required: true, message: "Please select the date" }]}>
                <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item>
                <Row gutter={16}>
                    {/* Start Time */}
                    <Col span={12}>
                    <Form.Item
                        name="start_time"
                        label="Start Time"
                        style={{ marginBottom: 0 }}
                    >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" />
                    </Form.Item>
                    </Col>
                    {/* End Time */}
                    <Col span={12}>
                    <Form.Item
                        name="end_time"
                        label="End Time"
                        dependencies={["start_time"]}
                        rules={[({ getFieldValue }) => ({ validator: validateEndTime(getFieldValue) })]}
                        style={{ marginBottom: 0 }}
                    >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" />
                    </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item name="location" label="Location">
                <Input placeholder="Enter Location (Optional)" />
            </Form.Item>

            {/* Hidden field for recurrence */}
            <Form.Item name="recurrence" style={{ display: "none" }}>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 0 }}>
                    {/* Recurring Event Toggle */}
                    {!isEdit && (
                    <div style={{ display: "flex", alignItems: "center" }}>

                    <label style={{ marginRight: "8px", lineHeight: "1.5" }}>
                        Recurring Event
                    </label>
                    <Form.Item name="is_recurring" style={{ marginBottom: 0 }}>
                        <Switch checked={isRecurring} onChange={handleToggleChange}/>
                    </Form.Item>
                    </div>
                    )}

                    {/* Buttons */}
                    <div style={{  display: "flex", justifyContent: "space-between",marginLeft: "auto"}}> {/* Ensure the button always aligns to the right */}
                        {/* Delete Button */}
                        {isEdit && (
                            <Button danger onClick={handleDeleteClick} style={{ marginRight: "10px" }} >
                                Delete
                            </Button>
                        )}
                        {/* Update Button */}
                        <Form.Item style={{ margin: "0px" }}>
                            <Button type="primary" htmlType="submit" >
                                {isEdit ? "Update" : "Add"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form.Item>
            <RecurrenceModal
                visible={isRecurrenceModalVisible}
                onCancel={handleRecurrenceModalCancel}
                onSave={handleRecurrenceModalSave}
                startDate={form.getFieldValue("date")} // Pass the selected date
            />
            <Modal
                title="Edit Recurring Event"
                visible={editRecurringEventModal}
                destroyOnClose
                onCancel={handleEditRecurringEvenCancel}
                zIndex={2000}
                width={"300px"}
                footer={[
                    <Button key="update" type="primary" onClick={handleEditRecurringEvenConfirm}>
                        Ok
                    </Button>,
                  ]}
            > 
                <Radio.Group
                        value={editOption}
                        onChange={(e) => setEditOption(e.target.value)}
                        style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                    >
                    <Radio value={EDIT_OPTIONS.EDIT_THIS}>This event</Radio>
                    <Radio value={EDIT_OPTIONS.EDIT_ALL}>All events</Radio>
                </Radio.Group>

            </Modal>
        </Form>
        {/* Recurring Event Delete Modal */}
        <Modal
            title="Delete Recurring Event"
            visible={deleteRecurringEventModal}
            onCancel={handleDeleteCancel}
            onOk={handleDeleteConfirm}
            zIndex={2000}
            width={"300px"}
            footer={[
                <Button key="update" type="primary" onClick={handleDeleteConfirm}>
                    Ok
                </Button>,
              ]}
        >
            <Radio.Group
            value={deleteOption}
            onChange={(e) => setDeleteOption(e.target.value)}
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
            <Radio value={DELETE_OPTIONS.DELETE_THIS}>This Event</Radio>
            <Radio value={DELETE_OPTIONS.DELETE_ALL}>All Events</Radio>
            </Radio.Group>
        </Modal>
        </>

    );
};

export default EventForm;
