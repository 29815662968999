import { React, useState, useEffect,useMemo } from "react";
import { Calendar, Modal, Button, message, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import EventForm from "./components/EventForm";
import { getSessions, getTeams,createEvent,getEvents,updateEvent,updateVirtualEvent,addException,deleteEvent} from "./api/events"; 
import EventModal from "./components/EventModal";

dayjs.extend(utc);
const { Option } = Select;

// Enum for delete options
const DELETE_OPTIONS = {
    DELETE_THIS: "deleteThis",
    DELETE_ALL: "deleteAll",
};

// Enum for edit options
const EDIT_OPTIONS = {
    EDIT_THIS: "editThis",
    EDIT_ALL: "editAll",
  };


const CalendarPage = () => {
    const [events, setEvents] = useState([]); // manage events
    const [sessions, setSessions] = useState([]);
    const [teams,setTeams] = useState([]);
    const [isEdit,setIsEdit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null); // best way to handle the selected date because the date changes dynamically based on user actions (calendar cell click or "Add Event" button)
    const [AddEventModalVisible, setAddEventModalVisible] = useState(false); // Tracks add event modal visibility
    const [filterEventType, setFilterEventType] = useState("All"); // Existing state for Entity Type filter
    const [selectedTeam, setSelectedTeam] = useState("All"); // New state for Team filter
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    // API call to get sessions,teams and events. sessions,teams passed into event form
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sessionsResponse, teamsResponse, eventsResponse] = await Promise.all([
                    getSessions(), // Fetch sessions
                    getTeams(),    // Fetch teams
                    getEvents()    // Fetch events
                ]);
                setSessions(sessionsResponse.data); // Set sessions data
                setTeams(teamsResponse.data);       // Set teams data
                setEvents(eventsResponse.data);     // Set events data
            } catch (error) {
                console.error("Error fetching data:", error);
                message.error("Failed to fetch sessions, teams, or events.");
            }
        };
        fetchData(); // Fetch sessions, teams, and events when the component mounts
        
    }, []);
    
    // Calendar cell rendering BEGIN
    // count events for each month
    const monthEventCounts = useMemo(() => {
        return events.reduce((acc, event) => {
          const eventMonth = dayjs(event.date).month();
          const eventYear = dayjs(event.date).year();
          const key = `${eventYear}-${eventMonth}`;
          if (!acc[key]) acc[key] = {};
          const type = event.entity_type.toLowerCase();
          acc[key][type] = (acc[key][type] || 0) + 1;
          return acc;
        }, {});
    }, [events]);
      
    const getMonthEventCounts = (current) => {
        const key = `${current.year()}-${current.month()}`;
        return monthEventCounts[key] || {};
    };
    const getListData = (value) => {
        const dateStr = dayjs(value).format("YYYY-MM-DD");
        return events.flatMap(event => {
        // For non-recurring events or virtual instances
        const eventDate = dayjs.utc(event.date).format("YYYY-MM-DD");
        const isSameDate = eventDate === dateStr;
        const matchesEntityType =
        filterEventType === "All" || event.entity_type.toLowerCase() === filterEventType.toLowerCase();
        const matchesTeam =
            selectedTeam === "All" || event.team_id === selectedTeam;

        return isSameDate && matchesEntityType && matchesTeam ? [event] : [];
        });
    };
    const getDotColor = (eventType) => {
        switch (eventType.toLowerCase()) {
          case "session":
            return "green";
          case "match":
            return "red";
          case "meeting":
            return "orange";
          default:
            return "blue"; // Default color
        }
      };

    const cellRender = (current, info) => {
        if (info.type === "date") {
            const listData = getListData(current) || [];
            return (
                <ul className="events" style={{ listStyle: "none", margin: 0, padding: 0 }}>
                    {listData.map((item) => (
                                <li key={item.event_id} style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
                                <span
                                    style={{
                                    display: "inline-block",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: getDotColor(item.entity_type),
                                    marginRight: "8px",
                                    flexShrink: 0 // prevent the dot from shrinking
                                    }}
                                />
                                <span
                                    onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering the date's onSelect
                                    handleEventModal(item);
                                    }}
                                    style={{ cursor: "pointer", fontWeight: "480", color: "black" }}
                                >
                                    {item.event_name}
                                </span>
                                </li>
                    ))} 
                </ul>
            );
        }else if (info.type === "month"){
            const monthCounts = getMonthEventCounts(current);
            return (
              <div style={{ textAlign: "center", fontWeight: "480" }}>
                {Object.entries(monthCounts).map(([type, count]) => (
                  <div key={type}>{`${type.charAt(0).toUpperCase() + type.slice(1)}: ${count}`}</div>
                ))}
              </div>
            );
        }
        return null;
    };

    const onSelectDate = (value, info) => {
        if (info.source === "date") {
        const selectedDate = dayjs(value);
        setSelectedDate(selectedDate);
        setAddEventModalVisible(true);
        }
    };
    // Calendar cell rendering END

    // Add Event Functions BEGIN
    const handleAddEventClick = () =>{ // handle "Add Event" button
        setSelectedDate(dayjs()); // set Today's date in Add event form
        setAddEventModalVisible(true);
    }
    const handleAddEventModalClose = () => {
        setAddEventModalVisible(false); 
    }

    const handleAddEvent = async (eventData) => { // send event to DT
        try {
          const response = await createEvent(eventData);
          const eventsResponse = await getEvents();
          setEvents(eventsResponse.data);
          message.success("Event added successfully!");
        } catch (error) {
          console.error("Error adding event:", error);
          message.error("Failed to add event.");
        } finally {
            setAddEventModalVisible(false); // Close modal
        }
    };
    // Add Event Functions END

    // Filter Functions BEGIN
    const handleTeamFilterChange = (value) => {
        setSelectedTeam(value);
    };
    const handleEventTypeFilterChange = (value) => {
        setFilterEventType(value);
    };
    // Filter Functions END

    //  Event Modal BEGIN
    const handleEventModal = (event) => {
        setEventModalVisible(true);
        setSelectedEvent(event);
    }
    const handleEventModalClose = (event) => {
        setEventModalVisible(false);
    }
    // Event Modal END

    // Event Update BEGIN
    const handleUpdateEvent = async (updatedEvent, editOption) => {
        console.log("[updatedEvent] ", updatedEvent);


        try {
          // Check if the event is recurring
          if (updatedEvent.is_recurring) {
            // Handle logic for recurring events based on editOption
            if (editOption === "editThis") {
                console.log("Updating this occurrence only");
                const response = await updateVirtualEvent(updatedEvent.event_id, updatedEvent);
                console.log("Edit This Response:", response.data);
                const eventsResponse = await getEvents();
                setEvents(eventsResponse.data);
            } else if (editOption === "editAll") {
                console.log("EDIT ALL")
                // Prepare the data for the API call
                const sanitizedEvent = { ...updatedEvent };
                delete sanitizedEvent.is_virtual;
                delete sanitizedEvent.parent_id;
                delete sanitizedEvent.session_id;
                delete sanitizedEvent.recurrence;
                const response = await updateEvent(updatedEvent.event_id, sanitizedEvent);
                message.success("Event updated successfully!");
                const eventsResponse = await getEvents();  
                setEvents(eventsResponse.data);
            }
          } else {
            // Handle regular non-recurring event
            delete updatedEvent.recurrence;
            delete updatedEvent.session_id;
            const response = await updateEvent(updatedEvent.event_id, updatedEvent);
            message.success("Event updated successfully!");
            // Update the events state
            setEvents((prevEvents) =>
                prevEvents.map((selectedEvent) =>
                    selectedEvent.event_id === updatedEvent.event_id ? updatedEvent : selectedEvent
                )
            );
            // Update the selectedEvent state
            setSelectedEvent(updatedEvent);
          }
        } catch (error) {
          console.error("Error updating event:", error);
          message.error("Failed to update the event.");
        } finally{
            setEventModalVisible(false);
        }
    };

    // Event Update END
    // Event Delete BEGIN
    const handleDeleteEvent = async (deletingEvent) =>{
        console.log("Here3");
        if (deletingEvent.deleteOption) {
            if (deletingEvent.deleteOption === DELETE_OPTIONS.DELETE_ALL) {
            // Handle "Delete All" logic here
                console.log("Delete All [deleteOption]:", deletingEvent.deleteOption)
                await deleteEvent(deletingEvent.event_id);
                message.success("All Events deleted successfully!");
                setEventModalVisible(false);
                const eventsResponse = await getEvents();
                setEvents(eventsResponse.data);

            } else if (deletingEvent.deleteOption === DELETE_OPTIONS.DELETE_THIS) {
            // Handle "Delete Only This" logic here
                console.log("Delete This [deleteOption]:", deletingEvent.deleteOption);
                await addException(deletingEvent.event_id, selectedEvent.date);
                message.success("Event deleted successfully!");
                setEventModalVisible(false);
                const eventsResponse = await getEvents();
                setEvents(eventsResponse.data);

            }
        } else {
            // Handle non-recurring delete logic
            await deleteEvent(deletingEvent.event_id);
            message.success("Event deleted successfully!");
            setEventModalVisible(false);
            setEvents((prevEvents) => prevEvents.filter((event) => event.event_id !== deletingEvent.event_id));

        }

    }
    // Event Delete END
    console.log("[Calendar Page] selectedEvent", selectedEvent);
    return(
        <div>
            {/* Buttons and filters div */}
            <div 
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,  
                }}
            >
                {/* Add Event button */}
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleAddEventClick}
                >
                    Add Event
                </Button>
                {/* Filters group */}
                <div 
                    style={{ 
                        display: "flex", 
                        gap: 8 
                    }}
                >
                    <Select
                        value={filterEventType}
                        onChange={handleEventTypeFilterChange}
                        style={{ width: 180 }}
                        placeholder="All Events"
                    >
                        <Option value="All">All Events</Option>
                        <Option value="session">Session</Option>
                        <Option value="match">Match</Option>
                        <Option value="meeting">Meeting</Option>
                    </Select>
            
                    <Select
                        value={selectedTeam}
                        onChange={handleTeamFilterChange}
                        style={{ width: 180 }}
                        placeholder="All Teams"
                    >
                        <Option value="All">All Teams</Option>
                        {teams.map((team) => (
                        <Option key={team.team_id} value={team.team_id}>
                            {team.team_name}
                        </Option>
                        ))}
                    </Select>
                </div>

            </div>
            <Calendar
            cellRender={cellRender}
            onSelect={onSelectDate}
            fullscreen={true}
            />
            <Modal
                open={AddEventModalVisible}
                onCancel={handleAddEventModalClose}
                footer={null}
                destroyOnClose
            >
                <EventForm
                    initialValues={{ date: selectedDate }}
                    onSave={handleAddEvent}
                    sessions={sessions}
                    teams = {teams}
                    isEdit={isEdit}
                />
            </Modal>
            
            <EventModal 
                visible={eventModalVisible}
                onCancel ={handleEventModalClose}
                event={selectedEvent}
                teams={teams}
                sessions={sessions}
                onUpdate={handleUpdateEvent}
                onDelete = {handleDeleteEvent}
            />

        </div>
        
    );
};

export default CalendarPage;