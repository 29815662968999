import { React, useState, useEffect,useMemo } from "react";
import {Modal, Button, message, Select, Spin, Descriptions } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Link } from "react-router-dom";
import EventForm from "./EventForm";

dayjs.extend(utc);

const EventModal = ({ visible, onCancel,onDelete, event,teams,sessions,onUpdate }) => {

    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const getTeamName = (teamId, teams) => {
        const team = teams.find((t) => t.team_id === teamId);
        return team ? team.team_name : "Unknown Team";
    };
    const formatDate = (date) => dayjs.utc(date).format("YYYY-MM-DD");
    const formatTime = (time) => {
        if (!time) return "N/A";
        // Allow both "HH:mm:ss" and "HH:mm"
        const parsed = dayjs(time, ["HH:mm:ss", "HH:mm"]);
        return parsed.isValid() ? parsed.format("HH:mm") : "N/A";
    };

    // Get the session name based on the entity_id
    const getSessionName = (entityId, sessions) => {
        const session = sessions.find((s) => s.session_id === entityId);
        return session ? session.session_name : "Unknown Session";
    };
    // Handle opening and closing the edit modal
    const handleEditClick = () => setIsEditModalVisible(true);
    const handleEditModalClose = () => setIsEditModalVisible(false);
    const handleUpdateEvent = (updatedEvent, editOption) => {
        // Pass the updated event back to the parent
        onUpdate(updatedEvent, editOption);
        // Close the edit modal
        setIsEditModalVisible(false);
    };
    // Prepare the event for the EventForm, date and time have to be dayjs objects and entity_id needs to be session_id
    const prepareEventForForm = (event) => {
        if (!event) return null;
    
        // Parse the rrule string to create a recurrence object
        const parseRRule = (rruleString) => {
            if (!rruleString) return null;
        
            // Split the string into lines, handling different newline characters
            const lines = rruleString.split(/\r?\n/);
        
            // Find lines that start with 'DTSTART:' and 'RRULE:'
            const dtstartLine = lines.find(line => line.startsWith('DTSTART:'));
            const rruleLine = lines.find(line => line.startsWith('RRULE:'));
        
            if (!dtstartLine || !rruleLine) {
                // Missing required lines
                console.error('Missing DTSTART or RRULE line.');
                return null;
            }
        
            // Extract DTSTART value using regex
            const dtstartMatch = dtstartLine.match(/^DTSTART:(\d{8})T\d{6}Z$/);
            if (!dtstartMatch) {
                // DTSTART format is invalid
                console.error('Invalid DTSTART format.');
                return null;
            }
            const dtstartRaw = dtstartMatch[1]; // e.g., "20250123"
            const startDate = dayjs(dtstartRaw, 'YYYYMMDD').format('YYYY-MM-DD');
        
            // Extract RRULE parameters
            const rruleContent = rruleLine.substring('RRULE:'.length);
            const rruleParams = rruleContent.split(';').reduce((acc, param) => {
                const [key, value] = param.split('=');
                acc[key] = value;
                return acc;
            }, {});
        
            // Extract FREQ (Frequency)
            const frequency = rruleParams.FREQ ? rruleParams.FREQ : null;
            if (!frequency) {
                // FREQ is required for frequency
                console.error('FREQ parameter is missing in RRULE.');
                return null;
            }
        
            // Extract INTERVAL
            const interval = rruleParams.INTERVAL ? parseInt(rruleParams.INTERVAL, 10) : 1;
        
            // Extract UNTIL (End Date)
            let endDate = null;
            if (rruleParams.UNTIL) {
                // UNTIL is in the format YYYYMMDDTHHmmssZ
                endDate = dayjs(rruleParams.UNTIL, 'YYYYMMDDTHHmmss[Z]');
                if (!endDate.isValid()) {
                    console.error('Invalid UNTIL date format.');
                    endDate = null;
                }
            }
        
            if (!endDate) {
                // UNTIL is required for the endDate
                console.error('UNTIL parameter is missing or invalid in RRULE.');
                return null;
            }
        
            // Construct the recurrence object
            const recurrence = {
                startDate,  // string in 'YYYY-MM-DD'
                frequency,  // string, e.g., "WEEKLY"
                interval,   // number
                endDate     // dayjs object
            };
        
            return recurrence;
        };

        return {
            ...event,
            date: event.date ? dayjs.utc(event.date): null, // Convert date to Day.js object
            start_time: event.start_time ? dayjs(event.start_time, "HH:mm:ss") : null,
            end_time: event.end_time ? dayjs(event.end_time, "HH:mm:ss") : null,
            session_id: event.entity_type === "Session" ? event.entity_id : null, // Map entity_id to session_id if event is a Session
            recurrence: event.rrule ? parseRRule(event.rrule) : null, // Parse and format the recurrence
        };
    };

    const eventForForm = prepareEventForForm(event);
    // Delete Event
    const handleDeleteEvent = (deletingEvent) =>{
        if (deletingEvent.deleteOption) {
            setIsEditModalVisible(false);
            // Send both id and deleteOption
            onDelete(deletingEvent);
        } else {
            setIsEditModalVisible(false);
            onDelete(deletingEvent);
        }

    }
    return (
            <>
            <Modal
                visible={visible}
                onCancel={onCancel}
                footer={null}
                bodyStyle={{padding:10}}
            >
                <div>
                    {event ? ( // Ensure event is not null before rendering its details
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label="Event Name">{event.event_name}</Descriptions.Item>
                            <Descriptions.Item label="Event Type">{event.entity_type}</Descriptions.Item>
                            {event.entity_type === "Session" && event.entity_id ? (
                                    <Descriptions.Item label="Session">
                                        {(() => {
                                            const sessionName = getSessionName(event.entity_id, sessions);
                                            const sessionUrl = `/sessions/${event.entity_id}`;
                                            return (
                                                <Link to={sessionUrl}>
                                                    {sessionName}
                                                </Link>
                                            );
                                        })()}
                                    </Descriptions.Item>
                            ) : null}
                            <Descriptions.Item label="Date">{formatDate(event.date)}</Descriptions.Item>

                            <Descriptions.Item label="Team Name">{getTeamName(event.team_id, teams)}</Descriptions.Item>
                            {event.start_time && (
                            <Descriptions.Item label="Start Time">
                                {formatTime(event.start_time)}
                            </Descriptions.Item>
                            )}
                            {event.end_time && (
                            <Descriptions.Item label="End Time">
                                {formatTime(event.end_time)}
                            </Descriptions.Item>
                            )}
                        
                            {event.location && event.location.trim() !== "" && event.location !== "EMPTY" ? (
                                <Descriptions.Item label="Location">
                                    {event.location}
                                </Descriptions.Item>
                            ) : null}

                        </Descriptions>
                    ) : (
                        <p>ERROR: No event selected.</p>
                    )}
                </div>
                {/*Edit Button */}
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button type="primary" onClick={handleEditClick}>
                        Edit
                    </Button>
                </div>
            </Modal>
            {/* Edit Modal */}
            <Modal
                visible={isEditModalVisible}
                onCancel={handleEditModalClose}
                footer={null}
                destroyOnClose
            >
                <EventForm
                    initialValues={eventForForm}
                    sessions={sessions}
                    teams={teams}
                    isEdit={true}
                    onUpdate={handleUpdateEvent}
                    onDelete= {handleDeleteEvent}
                />
            </Modal>
        </>
        
    );
};

export default EventModal;
