// src/api/events.js

import api from '../../../config/api';   // Assuming apiClient is set up with the base URL and headers

// Fetch all events
export const getEvents = () => api.get(`api/events`);

// Fetch all teams
export const getTeams = () => api.get('/api/teams');

// Fetch all sessions
export const getSessions = () => api.get('/api/sessions');

// Fetch team name by team ID
export const getTeamName = (teamId) => api.get(`/api/teams/${teamId}`);

// Delete an event by event ID
export const deleteEvent = (eventId) => api.delete(`/api/events/${eventId}`);

// Update an event by event ID with new data
export const updateEvent = (eventId, updatedEventData) => 
    api.put(`/api/events/${eventId}`, updatedEventData);

// Create a new event
export const createEvent = (eventData) => api.post('/api/events', eventData);

// Add an exception for a recurring event
export const addException = (eventId, eventDate) => 
    api.post(`/api/events/${eventId}/exception`, { eventDate });

export const updateVirtualEvent = (eventId, updatedEventData) => api.post(`/api/events/${eventId}/virtual`, updatedEventData);