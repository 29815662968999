import { React, useState, useEffect } from "react";
import { Modal, Form, Select, DatePicker, Button } from 'antd';

const { Option } = Select;

const RecurrenceModal = ({ visible, onCancel, onSave, startDate }) => {
    const [form] = Form.useForm();


    const onFinish = (recurrence) => {
        if (onSave) {
            onSave(recurrence); // Pass form values back to calendarpage
            form.resetFields();
        }
    };
    const handleCancel = () => {
        form.resetFields(); // Reset the form fields
        if (onCancel) {
            onCancel(); // Notify the parent about the cancellation
        }
    };
    return (
        <Modal
            visible={visible}
            title="Set Recurrence"
            onCancel={handleCancel}
            footer= {null}
        >
        <Form 
            form={form}
            name="recurrenceForm" 
            layout="vertical"
            onFinish={onFinish}
            >
                <Form.Item
                    name="frequency"
                    label="Frequency"
                    rules={[{ required: true, message: "Please select frequency" }]}
                >
                <Select placeholder="Select Frequency">
                    <Option value="DAILY">Daily</Option>
                    <Option value="WEEKLY">Weekly</Option>
                    <Option value="MONTHLY">Monthly</Option>
                    <Option value="YEARLY">Yearly</Option>
                </Select>
                </Form.Item>

                <Form.Item
                    name="interval"
                    label="Repeat Every"
                    rules={[{ required: true, message: "Please enter interval" }]}
                    initialValue={1}
                >
                <Select>
                    {Array.from({ length: 30 }, (_, i) => (
                    <Option key={i + 1} value={i + 1}>
                        {i + 1}
                    </Option>
                    ))}
                </Select>
                </Form.Item>

                <Form.Item
                    name="endDate"
                    label="End Date"
                    rules={[
                        { required: true, message: "Please select end date" },
                        ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || !startDate || value.isAfter(startDate)) {
                            return Promise.resolve();
                            }
                            return Promise.reject(new Error('End date must be after start date'));
                        },
                        }),
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                </Form.Item>
                {/* Add/Update Button */}
                <Form.Item style={{ marginBottom: 0,textAlign: 'right'}}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>

    );
};  
export default RecurrenceModal;
