// src/pages/HomePage.jsx

import React, { useState, useEffect } from "react";
import { Row, Col, message } from "antd";
import TodoList from "./components/TodoList";
import UpcomingEvents from "./components/UpcomingEvents";
import CurrentInjuries from "./components/CurrentInjuries";
import { getUpcomingEvents, getCurrentInjuries, getTeams } from "./api/home";

const Home = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [injuries, setInjuries] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const [upcomingEventsResponse, injuriesResponse, teamsResponse] = await Promise.all([
                getUpcomingEvents(), // Fetch upcoming events
                getCurrentInjuries(), // Fetch current injuries
                getTeams() // Fetch teams
            ]);

            // Set state with the fetched data
            setUpcomingEvents(upcomingEventsResponse.data);
            setInjuries(injuriesResponse.data);
            setTeams(teamsResponse.data);

            // Log the responses
            console.log("Upcoming Events Response:", upcomingEventsResponse.data);
            console.log("Injuries Response:", injuriesResponse.data);
            console.log("Teams Response:", teamsResponse.data);

        } catch (error) {
            console.error("Error fetching data:", error);
            message.error("Failed to fetch upcoming events, injuries, or teams.");
        }
    };

    // Fetch data when the component mounts
    fetchData();
}, []);



  const handleInjuryClick = (injury) => {
    console.log("Injury clicked:", injury);
  };
  console.log("passed into componnet ", upcomingEvents);
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          
          <UpcomingEvents events={upcomingEvents} teams={teams} />
        </Col>
        <Col xs={24} md={12}>
          <CurrentInjuries injuries={injuries} onInjuryClick={handleInjuryClick} />
          <TodoList />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
